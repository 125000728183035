import { BoxProps } from '@mui/material/Box'
import Container from '@mui/material/Container'
import { Breakpoint } from '@mui/material/styles'
import { PropsWithChildren } from 'react'

import { FlexCol } from './components/Flex'
import { Footer } from './components/Footer/Main'

interface Props extends BoxProps {
  container?: Breakpoint | null
}

const CustomBasePage = ({ children, container = 'lg', ...props }: PropsWithChildren<Props>) => {
  return (
    <FlexCol {...props} sx={{ alignItems: 'stretch', justifyContent: 'flex-start', marginBottom: 24, minHeight: '80vh', ...props.sx }}>
      {container ? (
        <Container maxWidth={container} style={{ alignItems: 'stretch', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          {children}
        </Container>
      ) : (
        children
      )}
      <Footer />
    </FlexCol>
  )
}

export default CustomBasePage
